import React from 'react';
import QuoteLayout from '../../components/QuoteLayout/quote-layout';
import QuoteContext from '../../context/quote-context';
import PeopleCount from '../../components/GetQuoteSteps/people-count';

export default function QuotePeoplePage() {
  return (
    <QuoteLayout>
      <QuoteContext.Consumer>
        {quote => (
          <PeopleCount set={quote.set} peopleCount={quote.peopleCount} />
        )}
      </QuoteContext.Consumer>
    </QuoteLayout>
  );
}
