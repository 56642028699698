import React from 'react';
import { useIntl } from 'react-intl';
import { LocalizedLink as Link } from 'gatsby-theme-i18n';
import FormContainer from '../Form/form-container';
import FormHeadline from '../FormHeadline/form-headline';
import CheckBox from '../Form/checkbox';
import GlassesWithWine from '../Icons/glasses-with-wine';
import SmallParty from '../Icons/small-party';
import Balloons from '../Icons/balloons';


export default function PeopleCount({ set, peopleCount }) {
  const intl = useIntl();
  const nextStepPath = '/get-quote/meal';

  return(
    <FormContainer>
      <FormHeadline>{intl.formatMessage({ id: "quotePeopleCountHeadline" })}</FormHeadline>
      <Link to={nextStepPath}>
        <CheckBox checked={peopleCount === '2'} handleClick={() => set({peopleCount: '2'})}>
          <div>
            <GlassesWithWine color={'#333'} />
            <div>{intl.formatMessage({ id: "quotePeopleOptionOne" })}</div>
          </div>
        </CheckBox>
      </Link>

      <Link to={nextStepPath}>
        <CheckBox checked={peopleCount === '3-6'} handleClick={() => set({peopleCount: '3-6'})}>
          <div>
            <SmallParty color={'#333'} />
            <div>{intl.formatMessage({ id: "quotePeopleOptionTwo" })}</div>
          </div>
        </CheckBox>
      </Link>

      <Link to={nextStepPath}>
        <CheckBox checked={peopleCount === '7-12'} handleClick={() => set({peopleCount: '7-12'})}>
          <div>
            <Balloons color={'#333'} />
            <div>{intl.formatMessage({ id: "quotePeopleOptionThree" })}</div>
          </div>
        </CheckBox>
      </Link>
    </FormContainer>
  );
}
