import React from 'react';

export default function Balloons({ color }) {
  return(
    <svg width="21px" height="25px" viewBox="0 0 21 25" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>balloons</title>
      <g id="Mobile" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Contact-People" transform="translate(-150.000000, -406.000000)">
          <g id="seven-to-twelve-people" transform="translate(20.000000, 394.000000)">
            <g id="balloons" transform="translate(130.000000, 12.000000)">
              <path d="M10.8907666,23.9779289 C11.0413622,21.2045425 11.0637658,19.1163452 10.9579775,17.7133372 C10.8521892,16.3103292 10.712952,15.2940488 10.5402659,14.6644961" id="Line" stroke={color} strokeWidth="0.5" strokeLinecap="square"></path>
              <path d="M10.8907666,23.9779289 C10.7060856,21.8033113 10.5072662,20.186868 10.2943083,19.1285991 C10.0813504,18.0703302 9.7501341,17.0528239 9.30065932,16.0760804" id="Line-Copy-3" stroke={color} strokeWidth="0.5" strokeLinecap="square"></path>
              <path d="M10.8907666,23.9310033 C10.5680083,22.1624219 10.2721776,20.861089 10.0032746,20.0270047 C9.73437164,19.1929203 9.30737869,18.2521962 8.72229579,17.2048324" id="Line-Copy-4" stroke={color} strokeWidth="0.5" strokeLinecap="square"></path>
              <path d="M10.8907666,23.9779289 C11.0246374,21.3879108 11.2168196,19.835672 11.467313,19.3212125 C11.633205,18.980506 11.9164924,18.3309782 12.4392964,17.4926903 C12.6170422,17.2076846 12.9504269,16.7354813 13.4394505,16.0760804" id="Line-Copy" stroke={color} strokeWidth="0.5" strokeLinecap="square"></path>
              <path d="M10.945178,23.9310033 C11.1374602,22.5129092 11.4337719,21.3475604 11.8341133,20.4349566 C12.2344546,19.5223529 13.1364397,18.4209564 14.5400683,17.1307672" id="Line-Copy-2" stroke={color} strokeWidth="0.5" strokeLinecap="square"></path>
              <path d="M16.3630568,-2.56047256e-14 C18.4709389,-2.56047256e-14 20.2113629,1.7284238 20.2113629,3.98381919 C20.2113629,5.20239727 19.2144642,6.87345133 18.8599864,6.87345133 C18.8599864,6.87345133 17.3538892,6.11254727 16.5707412,6.11254727 C15.7995984,6.11254727 14.9180681,6.21349186 14.351399,6.49292821 C13.9967692,5.35106327 12.8309012,4.45069864 12.3753897,4.24496166 C12.2005278,2.47448413 13.6783057,-2.56047256e-14 16.3630568,-2.56047256e-14 Z M14.3637318,1.0166938 C13.4541665,1.79692021 13.3995386,2.50884967 13.5605336,2.90891017 C14.4001443,1.36955691 15.2174163,0.891818873 16.7636464,0.962613581 C16.3490244,0.786616966 15.2732971,0.236467394 14.3637318,1.0166938 Z" id="Combined-Shape" fill={color}></path>
              <path d="M16.0847964,6.87345133 C18.2115049,6.87345133 20.6587976,8.68626766 20.6587976,11.2408157 C20.6587976,14.891895 16.6934616,17.0186344 14.645306,17.2048324 C12.9727672,16.3204554 11.6136968,13.3673156 11.6136968,11.3548769 C11.6136968,8.84360059 13.958088,6.87345133 16.0847964,6.87345133 Z M14.1186122,7.99255302 C13.5070989,8.38677988 12.9369593,9.77793174 13.1624808,10.2100466 C13.9514603,8.41568904 15.0191783,8.08551635 16.7824267,8.02502506 C16.6295321,7.57450261 15.3606233,7.19186055 14.1186122,7.99255302 Z" id="Combined-Shape" fill={color}></path>
              <path d="M9.8161397,4.18688417 C11.5429158,4.15883808 13.6078232,5.91112223 13.6490098,6.7616359 C13.3246107,6.87345133 11.6300563,8.09519794 11.1285364,9.69830928 C10.5747551,11.4684745 11.0385104,13.2854603 11.3260144,14.0536738 C11.1684223,14.4293038 10.8324663,14.6144247 10.4727488,14.6644961 C8.05466072,14.5498198 4.99142243,11.1526625 5.05877394,8.85344162 C5.1152408,6.92579691 6.30355772,4.24393509 9.8161397,4.18688417 Z M6.52940697,6.43073355 C5.82789854,7.82279619 6.12817097,8.70020126 6.52940697,9.09613073 C6.75523937,6.83344806 7.50053963,5.84918165 9.41239077,5.15030483 C8.81889074,5.15030483 7.23091541,5.03867091 6.52940697,6.43073355 Z" id="Combined-Shape" fill={color}></path>
              <path d="M4.3137823,9.63497609 C4.645841,10.5431866 5.43213563,12.3642271 6.41469148,13.2319668 C7.09705236,13.8345907 8.79008582,15.0893688 9.57713047,15.3658565 C9.35334613,16.3082157 9.09972179,16.908899 8.65831341,17.3362418 C4.0939671,17.4658983 2.32841322,15.0001881 2.32841322,12.9798644 C2.32841322,10.9595408 4.01528475,9.80350157 4.3137823,9.63497609 Z M2.86394944,13.4033589 C3.20676821,14.5516348 3.83492897,14.8911077 4.26588497,14.9067507 C3.20111623,13.5136168 3.31687287,12.1787272 4.10026077,11.0534011 C3.52640611,10.994392 2.52113067,12.255083 2.86394944,13.4033589 Z" id="Combined-Shape" fill={color}></path>
              <path d="M3.74150725,1.68990125 C6.02500912,1.68990125 7.2217058,3.61846239 7.2217058,3.98815165 C6.30189232,4.45568595 5.35327839,5.27439339 4.76867305,6.45242635 C4.18406771,7.63045931 4.25594821,9.19071151 4.3137823,9.63497609 C1.71296286,9.2243141 -9.53134752e-15,7.52092408 -9.53134752e-15,5.43733126 C-9.53134752e-15,3.54885748 1.50923989,1.68990125 3.74150725,1.68990125 Z M1.7287514,3.01368845 C0.989702448,3.86175038 1.02926678,4.53169279 1.22801172,4.88247436 C1.80682197,3.33723417 2.49906925,2.7830773 3.93178876,2.64179029 C3.527785,2.53353781 2.46780035,2.16562653 1.7287514,3.01368845 Z" id="Combined-Shape" fill={color}></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
